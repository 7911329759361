import React, { useRef, useLayoutEffect, useEffect, memo } from 'react';

type VideoProps = {
	src: string,
	play:boolean,
	loop?: boolean,
	muted?: boolean,
	onEnded?: () => void,
	className?:string,
	onVideoPlaybackError?: () => void
};

function Video({ src, muted = true, play, onEnded = () => {}, className = '', onVideoPlaybackError, loop = false }:VideoProps) {
	const ref = useRef<HTMLVideoElement>(null);

	useLayoutEffect(() => {
		ref.current.currentTime = 0;

		if (play && ref.current.paused) {
			ref.current.play().catch((e) => {
				onVideoPlaybackError?.();
			});
		} else if (!play && !ref.current.paused) {
			ref.current.pause();
		}
	}, [play, src]);

	useEffect(() => {
		if (ref.current) {
			ref.current.currentTime = 0;
			// ref.current.playbackRate = 0.5;
		}
	}, []);

	return (
		<video className={`${className} ${play ? 'playing' : ''}`} ref={ref} src={src} preload='preload' loop={loop} muted={muted} playsInline onEnded={onEnded} />
	);
}

export default memo(Video);