import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Home from './components/routes/Home';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import Debug from './components/Debug';
import Login from './components/routes/Login';
import Intro from './components/routes/Intro';
import Notifications from './components/ui/Notifications';
import VideoBackgrounds from './components/VideoBackgrounds';
import Feed from './components/routes/Feed';

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId, isLoaded } = usePlayFab();

	// Connect to realtime if the service is active in this app
	useRealtime();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);
	const showLogin = !playerId && !isLogin;

	return (
		<>
			{/* If we're not logged in, and we're not on the login page, redirect to the login page */}
			{showLogin && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Debug />
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={isLoaded && <Home />} />
					<Route path={ROUTES.FEED} element={<Feed />} />
					<Route path={ROUTES.INTRO} element={<Intro />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
				</Route>
			</Routes>
			<VideoBackgrounds />
			<Notifications position='bottom-right' />
		</>
	);
}

export default memo(App);
