import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { setBackgroundVisible, setPlayFeedLoop } from '../../redux/env';
import { useAppDispatch } from '../../redux/config/store';
import QRCode from '../icons/QRCode';
import { addNotification, notificationGenerator } from '../../redux/notifications';
import Button from '../ui/Button';

export default function Feed() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setPlayFeedLoop(true));
		dispatch(setBackgroundVisible(true));
	}, []);

	const onClick = () => {
		dispatch(addNotification(notificationGenerator({
			title: '',
			icon: 'small',
			message: '<span class="user">Maxime</span> gave <span class="streamer">KingMooran</span>: <span class="currency"><span class="amount">10</span> Water',
		})));
	};

	return (
		<CSSTransition in={true} timeout={2000} appear>
			<div className="page feed">
				{false && <Button onClick={onClick} label="Add Notification" />}
				<div className="qr-container">
					<QRCode />
					Participate<br />on your<br />phone!
				</div>
			</div>
		</CSSTransition>
	);
}