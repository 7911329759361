import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCatalog } from '../redux/catalog';

import { IRootState, useAppDispatch } from '../redux/config/store';
import { getItemInventory } from '../redux/inventory';
import { getPlayerCombinedInfo, setNeedRefresh } from '../redux/playfab';
import { getPlayerStatistics } from '../redux/statistics';
import { getTitleData } from '../redux/title_data';

export default function usePlayFab() {
	const {
		PlayFabId,
		DisplayName,
		AvatarUrl,
		currencies,
		needRefresh,
		isLoaded,
	} = useSelector((state:IRootState) => state.playfab);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (PlayFabId && needRefresh) {
			dispatch(setNeedRefresh(false));

			dispatch(getItemInventory());
			dispatch(getCatalog());
			dispatch(getPlayerCombinedInfo());
			dispatch(getPlayerStatistics());
			dispatch(getTitleData());
		}
	}, [PlayFabId, needRefresh]);

	return {
		playerId: PlayFabId,
		currencies,
		DisplayName,
		AvatarUrl,
		isLoaded,
	};
}