import React from 'react';
import { CDN_BASE } from '../Constants';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { setPlayDrop, setPlayFeedLoop } from '../redux/env';
import Video from './ui/Video';

export default function VideoBackgrounds() {
	const {
		playFeedLoop,
		playDrop,
		backgroundVisible,
	} = useAppSelector(state => state.env);

	const dispatch = useAppDispatch();

	const onEnded = () => {
		dispatch(setPlayFeedLoop(true));
		dispatch(setPlayDrop(false));
	};

	return (
		<div className={`video-backgrounds ${backgroundVisible ? 'visible' : ''}`}>
			<Video className="feed-loop" play={playFeedLoop} src={CDN_BASE + 'day-cycle-demo.mp4'} loop />
			<Video className="feed-drop" play={playDrop} src={CDN_BASE + 'drone_drop_night.mp4'} onEnded={onEnded} />
		</div>
	);
}