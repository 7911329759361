import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/config/store';
import { removeNotification } from '../../redux/notifications';
import { raw } from '../../utils/textUtils';

// eslint-disable-next-line no-unused-vars
type CloseNotificationFn = (notification: IXRRealtimeNotification) => void;

export const NotificationListPositions = {
	TOP_LEFT: 'top-left',
	TOP_RIGHT: 'top-right',
	TOP: 'top',
	BOTTOM_LEFT: 'bottom-left',
	BOTTOM_RIGHT: 'bottom-right',
} as const;
export type NotificationListPosition = typeof NotificationListPositions[keyof typeof NotificationListPositions];

function Notification({ notification, closeNotification }: { notification: IXRRealtimeNotification, closeNotification: CloseNotificationFn }) {
	const {
		Title: title,
		Message: message,
	} = notification;

	const [isAnimatingOut, setIsAnimatingOut] = useState(false);

	useEffect(() => {
		let animTimeout = null;
		const int = setTimeout(() => {
			setIsAnimatingOut(true);
			animTimeout = setTimeout(() => {
				closeNotification(notification);
			}, 1000);
		}, 5000);

		return () => {
			clearTimeout(int);
			clearTimeout(animTimeout);
		};
	}, []);

	const onClick = () => {
		setIsAnimatingOut(true);
		setTimeout(() => {
			closeNotification(notification);
		}, 1000);
	};

	return (
		<div className={`notification ${isAnimatingOut ? 'out' : ''}`} onClick={onClick}>
			<div className="content">
				<div className="title">{title}</div>
				<div className="description" {...raw(message)} />
			</div>
			<div className="close">&times;</div>
		</div>
	);
}

export default function Notifications({ position = NotificationListPositions.TOP_RIGHT }: { position?: NotificationListPosition }) {
	const listRef = useRef<HTMLDivElement>(null);

	const notifications = useAppSelector((state) => state.notifications.list);
	const dispatch = useAppDispatch();

	const sortedNotifications = position.includes('bottom')
		? [...notifications].reverse()
		: [...notifications];

	const handleScrolling = (el:HTMLElement) => {
		const isTopPosition = ([
			NotificationListPositions.TOP_LEFT,
			NotificationListPositions.TOP_RIGHT,
		] as NotificationListPosition[]).includes(position);
		if (isTopPosition) {
			el?.scrollTo(0, el.scrollHeight);
		} else {
			el?.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		handleScrolling(listRef.current);
	}, [notifications, position]);

	function closeNotification(notification: IXRRealtimeNotification) {
		dispatch(removeNotification(notification.NotificationId));
	}

	return (
		<div className={`notifications-list ${position}`} ref={listRef}>
			{sortedNotifications.map(n => <Notification key={n.NotificationId} notification={n} closeNotification={closeNotification} />)}
		</div>
	);
}