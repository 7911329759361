import React, { useEffect } from 'react';
import useLogin from '../../hooks/useLogin';
import usePlayer from '../../hooks/usePlayer';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../Constants';

const LS_KEY = 'mbc-sand-vault-tv-app';
export const saveUniqueIdentifier = (forceNew = false) => {
	let uid = localStorage.getItem(LS_KEY);
	
	if (!forceNew && uid) return uid;

	uid = 'MBC_TV_APP';
	localStorage.setItem(LS_KEY, uid);

	return uid;
};

export const setUniqueIdentifier = (s) => localStorage.setItem(LS_KEY, s);
export const getUniqueIdentifier = () => localStorage.getItem(LS_KEY);

export default function Login() {
	const {
		OnClickLoginWithUser,
		IsLoggingIn,
	} = useLogin();

	const { playerId } = usePlayer();

	useEffect(() => {
		if (!playerId) {
			OnClickLoginWithUser(saveUniqueIdentifier());
		}
	}, [playerId]);

	return (
		<div className="page login">
			{IsLoggingIn && <div className="loading">Logging in...</div>}
			{playerId && <Navigate to={ROUTES.ROOT} replace={true} />}
		</div>
	);
}