import React, { useCallback, useEffect, useRef } from 'react';
import { CDN_BASE, ROUTES } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

export default function Intro() {
	const navigate = useNavigate();

	const onEnded = useCallback(() => {
		navigate(ROUTES.ROOT + ROUTES.FEED);
	}, [navigate]);

	const video = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (video.current) {
			video.current.playbackRate = 1;
		}
	}, [video]);

	return (
		<CSSTransition in={true} timeout={1000} appear>
			<div className="page intro">
				<div className="video-container">
					<video ref={video} className='video' src={CDN_BASE + 'intro-app.mp4'} autoPlay playsInline muted onEnded={onEnded}></video>
				</div>
			</div>
		</CSSTransition>
	);
}