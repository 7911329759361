import React, { memo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CDN_BASE, ROUTES } from '../../Constants';
import Button from '../ui/Button';
import Video from '../ui/Video';
import { useNavigate } from 'react-router-dom';

function Home() {
	const navigate = useNavigate();

	const [isVisible, setIsVisible] = useState(true);

	const onClick = () => {
		setIsVisible(false);
	};

	return (
		<CSSTransition in={isVisible} timeout={{ enter: 3000, appear: 3000, exit: 600 }} appear onExited={() => navigate(ROUTES.INTRO)}>
			<div className="page home">
				<div className="logo">
					<img className="logo" width="799" height="419" src={CDN_BASE + 'logo-temp.png'} alt="" />
				</div>
				<Button onClick={onClick} label="Start" />
				<Video play={true} className="background" src={CDN_BASE + 'loop_splash.mp4'} loop />
			</div>
		</CSSTransition>
	);
}

export default memo(Home);
